import { createSlice } from '@reduxjs/toolkit';

const initialState={value:{show: false, value:''}};

export const errModalValidationSlice = createSlice({
    name: 'errorModalVal',
    initialState,
    reducers: {
        setErrModalVal: (state, action) => {
            state.value= action.payload;
        },
        setErrModalValInit: (state) => {
            state.value = initialState.value;
        }
    }
});

export const { setErrModalVal, setErrModalValInit } = errModalValidationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectErrModalVal = (state) => state.errorModalVal.value;

export default errModalValidationSlice.reducer;