import TooltipUser from '../RegistrationLogin/TooltipUser';
import { Row, Col } from 'react-bootstrap';
import { selectUserData, setUserData } from '../../../slice/userDataSlice';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../slice/messageProfileValidationSlice';
import { selectBearer, setExpired } from '../../../slice/bearerSlice';
import { Url } from '../../../constants/global';
import Country from '../RegistrationLogin/Country';
import { isMobile } from 'react-device-detect';
import SelectLanguage from './SelectLanguage';
import { selectLanguage, setLanguage } from '../../../slice/SettingSlice';
import { switchLanguageGA } from '../../../GoogleAnalytics4/GoogleAnalyticsEvents';
import { resetMessageLang } from '../../../slice/MessageSlice';
import { selectProfile } from '../../../slice/ModalVisibilitySlice';

function ChangePersonalInfo(props) {
    let messagePersonalInfo;
    const data = useSelector(selectUserData);
    const dispatch = useDispatch();
    const messageValidation = useSelector(selectMessageProfileVal);
    const logToken = useSelector(selectBearer);
    const [triggerAfterUpBearer, setTriggerAfterUpBearer] = useState(false);
    const language = useSelector(selectLanguage);
    const [disable, setDisable] = useState(false);
	const type = useSelector(selectProfile).type;

    useEffect(() => {
        // props.lang[1](language); //da vedere se serve (c'era nella modal dei settings)
        if (triggerAfterUpBearer) { //To trigger the function that had given 401 because the token had expired
            setTriggerAfterUpBearer(false);
            if (logToken !== '') {
                putInfo();
            } else {
                props.handleClose();
            }
        }
    }, [logToken])

    function reset(e) {
        e.preventDefault();
        closeProfileMessage();
        props.val[1]({ ...props.val[0], personalInfo: false });
        props.edit[1]({ ...props.edit[0], firstname: '', lastname: '', user: '', aff: '' });
        props.country[1](data.country);
        dispatch(setLanguage(props.lang[0]));
    }

    function closeProfileMessage (){
        dispatch(setMessageProfileVal(messageValidation.map((err) => {
            if (err.id === 'personalInfo') {
                return { ...err, show: false };
            } else {
                return { ...err };
            }})));
    }

    function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        props.val[1]({ ...props.val[0], personalInfo: true });
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (!((props.edit[0].firstname === '' || data.firstname === props.edit[0].firstname) && (props.edit[0].lastname === '' || data.lastname === props.edit[0].lastname) && (props.edit[0].user === '' || data.user === props.edit[0].user) && (props.edit[0].aff === '' || data.aff === props.edit[0].aff) && (props.country[0] === '' || data.country === props.country[0]) && props.lang[0] === language)) {
                if (props.lang[0] !== language) {
                    switchLanguageGA();
                    dispatch(resetMessageLang());
                }
                closeProfileMessage();
                putInfo();
            }
        }
    }

    function putInfo() {
        setDisable(true);
        const fetchPromise = fetch(Url + '/varchat/api/auth/change-personal-info', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
            body: JSON.stringify({
                firstName: props.edit[0].firstname,
                lastName: props.edit[0].lastname,
                username: props.edit[0].user,
                country: props.country[0],
                institution: props.edit[0].aff,
                lang: language
            })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 409) {
                        dispatch(setMessageProfileVal(messageValidation.map((err) => {
                            if (err.id === 'personalInfo') {
                                return { ...err, show: true, value: 'Username not available!', type: 'error' };
                            } else {
                                return { ...err };
                            }
                        })));
                        setDisable(false);
                        throw new Error(`HTTP error: ${response.status}`);
                    } else if (response.status === 401) {
                        dispatch(setExpired(true));
                        setTriggerAfterUpBearer(true);
                    } else {
                        setDisable(false);
                        throw new Error(`HTTP error: ${response.status}`);
                    }
                } else {
                    dispatch(setMessageProfileVal(messageValidation.map((err) => {
                        if (err.id === 'personalInfo') {
                            return { ...err, show: true, value: 'Personal info successfully changed!', type: 'success' };
                        } else {
                            return { ...err };
                        }
                    })));
                    return response.json();
                }
            })
            .then((data1) => {
                dispatch(setUserData({ ...data, firstname: data1.firstName, lastname: data1.lastName, user: data1.username, country: data1.country, aff: data1.institution }));
                props.val[1]({ ...props.val[0], personalInfo: false });
                props.edit[1]({ ...props.edit[0], firstname: '', lastname: '', user: '', aff: '' });
                props.lang[1](language);
                setDisable(false);
            })
            .catch((error) => {
                setDisable(false);
                console.error(`Could not get products: ${error}`);
            });
    }

    messageValidation.map((message) => {
        if (message.id === 'personalInfo' && message.show === true) {
            if (message.type === 'error') {
                messagePersonalInfo = <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 16a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 15a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 16c0 .283.096.52.287.712.192.192.43.288.713.288zm-1-4h2V7h-2v6zm-2.75 8L3 15.75v-7.5L8.25 3h7.5L21 8.25v7.5L15.75 21h-7.5zm.85-2h5.8l4.1-4.1V9.1L14.9 5H9.1L5 9.1v5.8L9.1 19z" fill="#ee8373" />
                    </svg>
                    <span className='errorMessage'>{message.value}</span></>;
            } else {
                messagePersonalInfo = <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.95 12.7 9.5 11.275A.933.933 0 0 0 8.812 11c-.274 0-.512.1-.712.3a.948.948 0 0 0-.275.7c0 .284.092.517.275.7l2.15 2.15c.2.2.433.3.7.3.267 0 .5-.1.7-.3l4.25-4.25c.2-.2.296-.433.287-.7a1.055 1.055 0 0 0-.287-.7 1.02 1.02 0 0 0-.713-.312.93.93 0 0 0-.712.287L10.95 12.7zm-2.8 9.05L6.7 19.3l-2.75-.6a.943.943 0 0 1-.6-.387.928.928 0 0 1-.175-.688L3.45 14.8l-1.875-2.15a.934.934 0 0 1-.25-.65c0-.25.083-.466.25-.65L3.45 9.2l-.275-2.825a.928.928 0 0 1 .175-.687.943.943 0 0 1 .6-.388l2.75-.6 1.45-2.45a.983.983 0 0 1 .55-.437.97.97 0 0 1 .7.037l2.6 1.1 2.6-1.1a.97.97 0 0 1 .7-.037c.233.075.417.22.55.437L17.3 4.7l2.75.6c.25.05.45.18.6.388.15.208.208.437.175.687L20.55 9.2l1.875 2.15c.167.184.25.4.25.65s-.083.467-.25.65L20.55 14.8l.275 2.825a.928.928 0 0 1-.175.688.943.943 0 0 1-.6.387l-2.75.6-1.45 2.45a.983.983 0 0 1-.55.438.97.97 0 0 1-.7-.038l-2.6-1.1-2.6 1.1a.97.97 0 0 1-.7.038.983.983 0 0 1-.55-.438zm1.3-1.8 2.55-1.1 2.6 1.1 1.4-2.4 2.75-.65-.25-2.8 1.85-2.1-1.85-2.15.25-2.8-2.75-.6-1.45-2.4L12 5.15l-2.6-1.1L8 6.45l-2.75.6.25 2.8L3.65 12l1.85 2.1-.25 2.85 2.75.6 1.45 2.4z" fill="#5ABEBA" />
                    </svg>
                    <span className='successMessage'>{message.value}</span></>;
            }
        }
        return null;
    })

    //html elem for the 2 visualization (pc and phone)
    const firstName = <Col><label>FIRST NAME</label>
        <Form.Control type="text" id='firstname' placeholder={data.firstname} minLength='3' pattern='([A-Za-zÀ-ÖØ-öø-ÿ]+[\s\.]*){3,}' value={props.edit[0].firstname} onChange={(e) => { props.edit[1]({ ...props.edit[0], firstname: e.target.value }); props.handleChange('personalInfo') }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter your name.</Form.Control.Feedback></Col>;

    const lastName = <Col><label>LAST NAME</label>
        <Form.Control type="text" id='lastname' placeholder={data.lastname} minLength='3' pattern="([A-Za-zÀ-ÖØ-öø-ÿ]+[\s\.']*){3,}" value={props.edit[0].lastname} onChange={(e) => { props.edit[1]({ ...props.edit[0], lastname: e.target.value }); props.handleChange('personalInfo') }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter your last name.</Form.Control.Feedback></Col>;

    const user = <Col><span><label>USERNAME</label> <TooltipUser /></span>
        <Form.Control type="text" id='user' placeholder={data.user} pattern='[A-Za-z0-9À-ÖØ-öø-ÿ\.]*' maxLength={32} value={props.edit[0].user} onChange={(e) => { props.edit[1]({ ...props.edit[0], user: e.target.value }); props.handleChange('personalInfo') }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid username.</Form.Control.Feedback></Col>;

    const aff = <Col><label>ORGANIZATION</label>
        <Form.Control type="text" id='aff' placeholder={data.aff} pattern='[A-zÀ-ú0-9\s\-_\.]*[A-zÀ-ú]{3,}[A-zÀ-ú0-9\s\-_\.]*' value={props.edit[0].aff} minLength={3} onChange={(e) => { props.edit[1]({ ...props.edit[0], aff: e.target.value }); props.handleChange('personalInfo', e) }} />
        <Form.Control.Feedback type="invalid" className='invalid' >Please enter your organization.</Form.Control.Feedback></Col>;

    const country = <Col><label>COUNTRY</label>
        <Country mystate={props.country[0] !== '' ? [props.country[0], props.country[1]] : [data.country, props.country[1]]} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please select a country.</Form.Control.Feedback></Col>;

    const languageCol = <Col><label>PREFERRED LANGUAGE</label>
        <SelectLanguage />
    </Col>

    return (
        <Form autoComplete='false' noValidate validated={props.val[0].personalInfo} onSubmit={(e) => { handleSubmit(e, 'personalInfo') }}>
            <div className='mb-4'>
                <div className='profile-label'>Personal info & settings</div>
                {isMobile ? <><Row className='input-row'>{firstName}</Row>
                    <Row className='input-row'>{lastName}</Row>
                    <Row className='input-row'>{aff}</Row>
                    <Row className='input-row'>{user}</Row>
                    <Row className='input-row'>{country}</Row>
                    <Row className='input-row'>{languageCol}</Row></>
                    : <><Row className='input-row'>{firstName}{lastName}</Row>
                        <Row className='input-row'>{aff}{user}</Row>
                        <Row className='input-row'>{country}{languageCol}</Row>
                    </>
                }
                <Row><Col className='display-align-center' md={{ offset: 6 }} lg={{ offset: 6 }}>
                    <Button className="secondary-button" type='reset' onClick={(e) => reset(e)} disabled={disable}>RESET</Button>
                    <Button className="ms-4 primary-button" type='submit' disabled={disable}>SAVE</Button>
                </Col>
                </Row>
                {type === 'translationButton' ? <div className='profile-message mt-4'>To translate your answer, please select your preferred language from the drop-down menu.</div> : null}
                {messageValidation[0].show ? <div className='profile-message mt-4'> {messagePersonalInfo}</div> : null}
            </div></Form>
    );
}

export default ChangePersonalInfo;