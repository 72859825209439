import './sideMenu.css';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { selectDevice } from '../../slice/deviceSlice';
import MenuButton from './MenuButton';
import varchatLogo from '../../Images/VarChat Logo DB_SVG.svg';
import SubscriptionButton from "./NewsletterSubscription/SubscriptionButton";
import LoginButton from './RegistrationLogin/LoginButton';
import SideMenuFooter from './SideMenuFooter/SideMenuFooter';
import InfoButton from './InfoBox/InfoButton';
import { selectShowSide } from '../../slice/SideMenuSlice';
import { selectBearer } from '../../slice/bearerSlice';
import ProfileButton from './ProfileSettings/ProfileButton';
import LogOut from './RegistrationLogin/LogOut';
import SideMenuModals from './SideMenuModals';
import HelpButton from './HelpCenter/HelpButton';
import { setInfo, setShowHelpCenter, setShowProfile } from '../../slice/ModalVisibilitySlice';

function SideMenu() {
    const dispatch = useDispatch();;
    const device = useSelector(selectDevice);
    const openMenu = useSelector(selectShowSide);
    const logToken = useSelector(selectBearer);

    function closePages(){
        dispatch(setShowProfile({show: false, type: 'profileButton'}));
        dispatch(setInfo(false));
        dispatch(setShowHelpCenter(false));
    }

    function handleLogoClick() {
        dispatch(setShowProfile({show: false, type: 'profileButton'}));
        dispatch(setInfo(false));
        dispatch(setShowHelpCenter(false));
    }

    return (
        <>
        <Col name='side-menu' id='side-menu' className={device + (openMenu ? ' side-menu-exp' : ' side-menu-close')} onClick={closePages}>
            <span name='side-header'>
                <div name='logo' onClick={handleLogoClick}>
                    <img src={varchatLogo} alt='VarChat logo' style={{cursor:'pointer'}}></img>
                </div>
                {device==='phone' ? <MenuButton/> : null}
            </span>
            <div name='side-menu-body' style={{display: device=== 'pc' || openMenu ? 'flex' : 'none'}}>
                <span name='upper-body'>
                    {logToken !== '' ? <ProfileButton /> : null}
                    <HelpButton />
                    <InfoButton />
                    {logToken === '' ? <><SubscriptionButton /> <LoginButton/></> : <LogOut />}
                </span>
                <span name='bottom-body'>
                    <SideMenuFooter /> 
                </span>
            </div>
        </Col>
        <SideMenuModals />
        </>
    );
}

export default SideMenu;