import {useState} from 'react';
import Form from 'react-bootstrap/Form';

function SelectField(props) {
    const {setPromptFields} = props;
    const [selectValue, setSelectValue] = useState('');

    function onSelectField(e){
        setSelectValue(e.target.value);
        setPromptFields((state) => { return { ...state, field: e.target.value } }); 
    }

    return (
        <><Form.Select id="field" name="field" value={selectValue} onChange={(e) => { onSelectField(e) }} >
            <option value='' disabled='disabled' defaultValue={true}>your field</option>
            <option value='Clinical Research'>Clinical Research</option>
            <option value='Clinical Care'>Clinical Care</option>
            <option value='Genetic Counselling'>Genetic Counselling</option>
            <option value='Clinical Testing'>Clinical Testing</option>
            <option value='Diagnostics'>Diagnostics</option>
            <option value='Translational Research'>Translational Research</option>
            <option value='Basic Research'>Basic Research</option>
            <option value='Education'>Education</option>
            <option value='Other'>Other</option>
        </Form.Select>
        {selectValue==='Other' && <><input type='text' className='prompt-engineering-input mt-3' maxLength='200' placeholder='Your field*' onChange={(e) => {setPromptFields((state) => { return { ...state, field: e.target.value.trim() } })}}></input> </>}</>
    );
}

export default SelectField;