import { createSlice } from '@reduxjs/toolkit';

const initialState = { activeConversationUuid: null, isTopicVisible: false, isTopicClickable: true };

export const ConversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        setActiveConversationUuid: (state, action) => {
            state.activeConversationUuid = action.payload;
            state.isTopicVisible = false;
        },
        setIsTopicVisible: (state, action) => {
            state.isTopicVisible = action.payload;
        },
        setIsTopicClickable: (state, action) => {
            state.isTopicClickable = action.payload;
        }
    }
});

export const { setActiveConversationUuid, setIsTopicVisible, setIsTopicClickable } = ConversationSlice.actions;

export const selectActiveConversationUuid = (state) => state.conversation.activeConversationUuid;
export const selectIsTopicClickable = (state) => state.conversation.isTopicClickable;
export const selectIsTopicVisible = (state) => state.conversation.isTopicVisible;

export default ConversationSlice.reducer;