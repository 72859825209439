import { createSlice } from '@reduxjs/toolkit';

//{ message:[{text:initialMessage, user:'VarChat', evaluate: false, rate: false, comment:'', userInput:'', cits:[], statusCode: 0, citCount: n, rcv_cit_count: 36, rcv_cits:[], language: 'EN', translation: ''}] }; //example of VarChat response message
//{evaluate: false, rate: 0, comment:'', userInput:'', cits:[], statusCode: 0, citCount: n, rcv_cit_count: 36, rcv_cits:[], language: 'EN', translation: ''} are fields use only to give an evaluation to the response receive by VarChat, are not used for the other messages

const initialMessage = 'Hello! I’m VarChat, your Generative AI assistant for interpreting human genomic variations.\nHere are some examples of what I can do for you:'
const initialState = { message: [{ text: initialMessage, user: 'VarChat' }] }; //da vedere se statusCode: 0 in questo stato iniziale evita che venga sovrascritto quando faccio abort del segnale

export const MessageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setMessage: (state, action) => {
console.log('\n+++++++++++++++++++++++++++ setMessage', action.payload);
            state.message.push(action.payload);
        },
        deleteMessage: (state, action) => {
            state.message.pop();
        },
        initMessage: (state) => {
            const time = state.message[0].time;
            let init = {time: time};
            init = Object.assign({}, initialState.message[0], init)
            state.message = [init];
        },
        updateMessage: (state, action) => {
            state.message = state.message.map((mex, j) => {
                if (action.payload.i === j) {
                    return Object.assign({}, mex, action.payload.obj);
                } else {
                    return { ...mex }
                }
            })
        },
        updateMessageText: (state, action) => {
            state.message = state.message.map((mex, j) => {
                if (action.payload.i === j) {
                    const newText = mex.text + (action.payload.text === '\n' && (mex.text=== '' || mex.text.match(/\n$/)) ? '' : action.payload.text);
                    return { ...mex, text: newText };
                } else {
                    return { ...mex }
                }
            })
        },
        updateMessageTranslation: (state, action) => {
            let lang;
            let transObj = {};
            state.message = state.message.map((mex, j) => {
                if (action.payload.i === j) {
                    lang = mex.language;
                    transObj[lang] = mex.translation[lang] + action.payload.translation;
                    const updateTrasObj = Object.assign({},mex.translation, transObj);
                    return { ...mex, translation: updateTrasObj };
                } else {
                    return { ...mex }
                }
            })
        },
        setMessageTranslation: (state, action) => {
            state.message = state.message.map((mex, j) => {
                if (action.payload.i === j) {
                    return { ...mex, translation: action.payload.translation };
                } else {
                    return { ...mex }
                }
            })
        },
        resetMessageLang: (state) => {
            state.message = state.message.map((mex) => {
                if (mex.evaluate) {
                    return { ...mex, language: 'EN' };
                } else {
                    return { ...mex }
                }
            })
        },
        setAllTopicMessagesAsObsolete: (state, action) => {
            console.log('setAllTopicMessagesAsObsolete')
            state.message = state.message.map((mex) => {
                if (mex.type === 'topic') {
                    return { ...mex, isObsolete: true };
                } else {
                    return { ...mex }
                }
            })
        }
    }
});

export const { setMessage, initMessage, deleteMessage, setMessageTranslation, resetMessageLang, updateMessageText, updateMessage, updateMessageTranslation, setAllTopicMessagesAsObsolete } = MessageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectMessages = (state) => state.messages.message;

export default MessageSlice.reducer;