import { createSlice } from '@reduxjs/toolkit';

const initialState = { queryDoneCounter: 0, counterInitTime: 0, resetTime: 86400000}; // 24H

export const unloggedUserSlice = createSlice({
    name: 'unlogged',
    initialState,
    reducers: {
        setCounterPlusOne: (state, action) => {
            if(state.counterInitTime === 0) state.counterInitTime = action.payload;
            if(action.payload - state.counterInitTime > state.resetTime) {
                 state.counterInitTime = action.payload;
                 state.queryDoneCounter = 1;
            } else {
                state.queryDoneCounter += 1;
            }
        }
    }
});

export const { setCounterPlusOne } = unloggedUserSlice.actions;

export const selectQueryDoneCounter = (state) => state.unlogged.queryDoneCounter;
export const selectCounterInitTime = (state) => state.unlogged.counterInitTime;
export const selectCounterResetTime = (state) => state.unlogged.resetTime;


export default unloggedUserSlice.reducer;