import { createSlice } from '@reduxjs/toolkit';

//firstRender e clickTranslate servono per far funzionare rispettivamente i reload e i focus dei messaggi
//value fa riferimento al loader che viene lanciato durante la ricerca, mentre translating serve per disabilitare la select durante la traduzione
const initialState={value:false, firstRender:true, traslating: false, clickTranslate: {show: false}, showLoader: false, waitingForMaxQueryResponse: false };

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setStreaming: (state, action) => {
            state.value= action.payload;
            state.showLoader= action.payload;
        },
        setFirstRender: (state, action) => {
            state.firstRender = action.payload;
        },
        setTranslating: (state, action) => {
            state.traslating= action.payload;
        },
        setClickTranslate: (state, action) => {
            state.clickTranslate= action.payload;
        },
        setShowLoader: (state, action) => {
            state.showLoader= action.payload;
        },
        setWaitingForMaxQueryResponse: (state, action) => {
            state.waitingForMaxQueryResponse= action.payload;
        }
    }
});

export const { setStreaming, setFirstRender, setTranslating, setClickTranslate, setShowLoader, setWaitingForMaxQueryResponse } = loaderSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectStreaming = (state) => state.loader.value;
export const selectFirstRender = (state) => state.loader.firstRender;
export const selectTranslating = (state) => state.loader.traslating;
export const selectClickTranslate = (state) => state.loader.clickTranslate;
export const selectShowLoader = (state) => state.loader.showLoader;
export const selectWaitingForMaxQueryResponse = (state) => state.loader.waitingForMaxQueryResponse;


export default loaderSlice.reducer;