import { createSlice } from '@reduxjs/toolkit';

const initialState={value: {firstname:"", lastname: "", user: "", email:"", aff:"", country:""}};

export const userDataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.value= action.payload;
        },
        resetUserData: (state) =>{
            state.value = initialState.value;
        }
    }
});

export const { setUserData, resetUserData } = userDataSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectUserData = (state) => state.data.value;

export default userDataSlice.reducer;