import { useState } from 'react';
import Form from 'react-bootstrap/Form';

function SelectAim(props) {
    const { setPromptFields } = props;
    const [selectValue, setSelectValue] = useState('');

    function onSelectAim(e) {
        setSelectValue(e.target.value);
        setPromptFields((state) => { return { ...state, aim: e.target.value } });
    }

    return (
        <>
            <Form.Select id="aim" name="aim" value={selectValue} onChange={(e) => { onSelectAim(e) }}>
                <option value='' disabled defaultValue={true}>your aim</option>
                <option value='Retrieve publications related to variants'>Retrieve publications related to variants</option>
                <option value='Summarize relevant information to draft clinical reports'>Summarize relevant information to draft clinical reports</option>
                <option value='Summarize relevant information to interpret variants'>Summarize relevant information to interpret variants</option>
                <option value='Summarize relevant information to draft research contents'>Summarize relevant information to draft research contents</option>
                <option value='Other'>Other</option>
            </Form.Select>
            {selectValue === 'Other' && <input type='text' className='prompt-engineering-input mt-3' maxLength='200' placeholder='Your aim*' onChange={(e) => { setPromptFields((state) => { return { ...state, aim: e.target.value.trim() } }) }}></input>}
        </>
    );
}

export default SelectAim;