import {Overlay} from "react-bootstrap";
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectMessages } from '../../slice/MessageSlice';
import { copyLinkGA, copyMessageTextGA } from '../../GoogleAnalytics4/GoogleAnalyticsEvents';
import {Url} from '../../constants/global';
import copyIcon from '../../Images/copy.svg';
import copyLinkIcon from '../../Images/copyLink.svg';

function CopyIcon(props){
    const [showCopyTool, setShowCopyTool] = useState(false);
    const copyRef= useRef(null);
    const message = useSelector(selectMessages)[props.ind];
    const legend = [
        {type:'copyText', tool:'Copied!', func: copy, icon:copyIcon},
        {type:'copyLink', tool:'Link copied!', func:copyLink, icon: copyLinkIcon}
    ]
    const index= legend.findIndex((el)=> {return el.type===props.type});
    const link= props.type ==='copyLink' ? Url+'/search?'+ new URLSearchParams({text: Object.values(message.userInput || {}).filter((str)=> str!=='').join(':')}) :'';

    function copy(e){
        copyMessageTextGA();
        e.stopPropagation();
        navigator.clipboard.writeText(props.messageToCopy + copyCits() + copyClinVar());
        setShowCopyTool(true);
        setTimeout(() => {
        setShowCopyTool(false);
        }, 500);
    }

    function copyCits(){
        if(message.cits!==undefined && message.cits.length!==0){
            return '\n\nReferences\n' + message.cits.map((ref)=>{
                return ref.cit;
            }).join('\n');
        } else {
            return '';
        }
    }

    function copyClinVar(){
        if(message.rcvCits!==undefined && message.rcvCits.length!==0){
            return '\n\nClinVar\n' + message.rcvCits.map((ref)=>{
                return ref.rcv +'\t'+ref.condition+'\t'+ ref.clinv_table[0].clin_sig+'\t'+getStars(ref.clinv_table[0].rev_status).join('') + (ref.clinv_table.length === 1 ? '' : ref.clinv_table.map((ref_el, i) => {
                    if (i !== 0) {
                        return '\t\t'+ref_el.clin_sig + '\t'+getStars(ref_el.rev_status)
                    } else {
                        return ''
                    }
                }).join('\n'));
            }).join('\n');
        } else {
            return '';
        }
    }

    function getStars(count){
        let stars=[];
        for(let i=0; i<count; i++){
            stars.push('⭐️');
        }
        return stars;
    }

    function copyLink(e){
        copyLinkGA();
        e.stopPropagation();
        navigator.clipboard.writeText(link);
        setShowCopyTool(true);
        setTimeout(() => {
        setShowCopyTool(false);
        }, 500);
    }

    return(
        <>
            <div className='copy-icon display-align-center' onClick={legend[index].func}  ref={copyRef}><img src={legend[index].icon} /></div>
            <Overlay target={copyRef} show={showCopyTool} placement = 'top'>
                            {({ placement: _placement,
                                arrowProps: _arrowProps,
                                show: _show,
                                popper: _popper,
                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                ...props
                            }) => (
                                <div className='tooltip-copy' {...props}>{legend[index].tool}</div>
                            )}
                </Overlay>
        </> 
    );
}

export default CopyIcon;