
import { createSlice } from '@reduxjs/toolkit';

const initialState = {ratings: {}};

export const topicRatingSlice = createSlice({
    name: 'topicstars',
    initialState,
    reducers: {
        setTopicRating: (state, action) => {
            state.ratings[action.payload[0]] = action.payload[1];
        },
        deleteObsoleteRatings: (state, action) => {
            state.ratings = initialState.ratings;
        }
    }
});

export const { setTopicRating, deleteObsoleteRatings } = topicRatingSlice.actions;
export const selectTopicRatings = (state) =>  state.topicstars.ratings;

export default topicRatingSlice.reducer;