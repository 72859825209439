import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "../../slice/SettingSlice";
import { selectShowMaximumQueryLimit, setShowMaximumQueryLimit } from "../../slice/ModalVisibilitySlice";
import { isMobile } from 'react-device-detect';

function MaximumQueryWarningModal() {
    const theme = useSelector(selectTheme);
    const dispatch = useDispatch();
    const show = useSelector(selectShowMaximumQueryLimit);

    function handleClose() {
        dispatch(setShowMaximumQueryLimit(false));
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}  backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false}>
                <Modal.Header>
                    <Modal.Title className='title'>

                    </Modal.Title>
                    <Button onClick={handleClose} id='close' className='close-button'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <p>You’ve exceeded the maximum number of allowed queries per day.</p>
                    <p>If you want to know how to increase this limit, please contact varchat@engenome.com</p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MaximumQueryWarningModal;