import React from 'react';
import { useState, useEffect } from 'react';
import { selectBearer, setExpired, selectController } from '../../slice/bearerSlice';
import { deleteMessage, selectMessages, setMessage, updateMessage, updateMessageText, setAllTopicMessagesAsObsolete } from '../../slice/MessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Url } from '../../constants/global.js';
import { useCacheBuster } from "react-cache-buster";
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { setShowLoader, setStreaming, selectTranslating } from '../../slice/loaderSlice';
import { setShowExpiredQuestion } from "../../slice/ModalVisibilitySlice";
import { setLineBreak } from '../../slice/InputMessageSlice';
import { setIsTopicClickable, selectIsTopicClickable } from "../../slice/ConversationSlice";


const QuestionRow = function Message(props) {
    const messages = useSelector(selectMessages);
    const { checkCacheStatus } = useCacheBuster();
    const controller = useSelector(selectController);
    const signal = controller.signal;
    const logToken = useSelector(selectBearer);
    const [triggerAfterUpBearer, setTriggerAfterUpBearer] = useState(false);
    const question = props.question;
    const conversationUuid = props.conversationUuid;
    const dispatch = useDispatch();
    const topicCreationDate = props.dateTime;
    const initialSearchCits = props.cits;
    const initialSearchCitCount = props.citCount;
    const msValidTimeAfterGeneration = 172800000; // 48H

    useEffect(()=>{
        if(triggerAfterUpBearer) {
            setTriggerAfterUpBearer(false);
            if(logToken !== '') handleClick();
        }
    }, [logToken])

    const handleClick = (e) => {
        if(!isQuestionExpired()) {
            dispatch(setIsTopicClickable(false));
            persistQuestionMessage();
            sendQuestion();
        } else {
            dispatch(setShowExpiredQuestion(true));
        }
    };

    const handleDblClick = (e) => {
        e.preventDefault();
    }

    const persistQuestionMessage = (e) => {
        dispatch(setMessage({
            text: question.question,
            user: 'You',
            userInput: question.question,
            statusCode: 0,
            language: 'EN',  //
            statusInfoCode: 0,
            type: 'question'
        }));
    };

    const replicateTheOriginalTopicMessage = (e) => {
        var sameConversationTopicMessages = messages.filter(function(message) { return message.type === 'topic' && message.conversationUuid === conversationUuid; });        
        dispatch(setAllTopicMessagesAsObsolete());
        if(sameConversationTopicMessages.length > 0) {
            dispatch(setMessage(Object.assign({}, sameConversationTopicMessages[0], {duplicatedMessage: true, isObsolete: false})));
        }
    }

    const isQuestionExpired = () => {
        return (Date.now() - new Date(topicCreationDate)) > msValidTimeAfterGeneration;
    };

    async function sendQuestion() {
        if(logToken) {
            checkCacheStatus();
            try {
                await streamSummary();
            } catch (error) {
                dispatch(setIsTopicClickable(true));
            }
        } else {
            // non loggato
        }
    }

    async function streamSummary(param, text) {
        dispatch(setStreaming(true));
        dispatch(setLineBreak(false));
        dispatch(setShowLoader(true));
        let jsonData = {};
        let headers;
        logToken
            ? headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${logToken}`}
            : headers = {'Content-Type': 'application/json'};
        await fetchEventSource(Url + '/varchat/api/request/answer?' + new URLSearchParams({ conversation_uuid: conversationUuid, question: question.question, question_uuid: question.question_uuid, stream: true }), {
            method: 'GET',
            headers: headers,
            openWhenHidden: true,
            signal: signal,
            onopen(response) {
                if (!response.ok) {
                    if (response.status === 401) {
                        dispatch(setExpired(true));
                        setTriggerAfterUpBearer(true);
                    }
                    throw new Error(response.status);
                } else {
                    return;
                }
            },
            onmessage(msg) {
                try {
                    const data = JSON.parse(msg.data);
            //        console.log('onmessage data', data);
                    if (Object.keys(jsonData).length === 0) {
                        dispatch(setShowLoader(false));
                        dispatch(setMessage({
                            text: "",
                            user: 'VarChat',
                            userInput: text,
                            statusCode: data.status_answer_code,
                            language: 'EN',
                            statusInfoCode: data.status_info_code,
                            type: 'answer',
                            conversationUuid: conversationUuid,
                            answerUuid: data.answer_uuid
                        }));
                        jsonData = {
                            evaluate: true,
                            cits: initialSearchCits,
                            citCount: initialSearchCitCount
                        };
                        replicateTheOriginalTopicMessage();
                    } else {
                        if (data.status_answer_code !== undefined) {
                            jsonData = Object.assign(jsonData, { statusCode: data.status_answer_code });
                        } else {
                            dispatch(updateMessageText({ i: messages.length + 1, text: data }));
                        }
                    }
                } catch {
                    const word = msg.data === '' ? '\n' : msg.data;
                    dispatch(updateMessageText({ i: messages.length + 1, text: word }));
                }
            },
            onclose() {
                dispatch(updateMessage({ i: messages.length + 1, obj: jsonData }));
                dispatch(setStreaming(false));
                dispatch(setIsTopicClickable(true));
                //                dispatch(setStreaming(false)); moved to use effect of messages (for scrolling purposes)
            },
            onerror(error) {
                dispatch(setStreaming(false));
                dispatch(deleteMessage());
                dispatch(setIsTopicClickable(true));
                if(error.includes("401")) dispatch(setMessage({ user: 'VarChat', statusCode: 5 }));
                throw error;
            }
        });
    }

    return (
        <div className="example" onClick={handleClick} onDoubleClick={handleDblClick} style={{marginRight: "16px", width: "100%", display: "flex", justifyContent: "space-between"}}>
            <span style={{fontSize:'15px'}}>{question.question}</span>
            <img src="/static/media/arrow.c526c2948ba05c95e5d68715dfd30546.svg" alt="arrow" data-cmp-ab="2" data-cmp-info="10"></img>
        </div>
    )
}

export default QuestionRow;