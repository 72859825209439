import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { selectBearer, setExpired } from '../../slice/bearerSlice';
import QuestionRow from './QuestionRow';
import ReadMore from './ReadMore';
import { Url } from '../../constants/global.js';
import SingleTopicRating from './Rating/SingleTopicRating';


const TopicContainer = function Message(props) {
    const topic = props.topic;
    const conversationUuid = props.conversationUuid;
    const cits = props.cits;
    const citCount = props.citCount;
    const dispatch = useDispatch();
    const logToken = useSelector(selectBearer);
    const [expand, setExpand] = useState(false);
    const [registeredClick, setRegisteredClick] = useState(false);
    const [triggerAfterUpdatingBearer, setTriggerAfterUpdatingBearer] = useState(false);

    useEffect(()=>{
        if(triggerAfterUpdatingBearer) {
            setTriggerAfterUpdatingBearer(false);
            if(logToken !== '') handleClick();
        }
    }, [logToken]);

    const handleClick = (e) => {
        if(!registeredClick) {
            const fetchPromise = fetch(Url + '/varchat/api/user-info/topic/' + topic.topic_uuid + '/select', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
            });
            fetchPromise
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            dispatch(setExpired(true));
                            setTriggerAfterUpdatingBearer(true);
                        }
                        throw new Error(`HTTP error: ${response.status}`);
                    } else {
                        setRegisteredClick(true);
                    }
                }).catch((error) => {
                    console.log(error)
                });
        }
    };

    return (
        <div className='message-section' onClick={handleClick}>
            <div name='ref-title' onClick={() => setExpand(!expand)}>
                <span>
                    <p style={{fontSize: '16px'}}>{topic.topic}</p>
                </span>
                <ReadMore expand={expand} setExpand={setExpand} messageShow={"SEE QUESTIONS"} messageHide={"HIDE QUESTIONS"} />
            </div>
            <div className={expand ? "section-exp" : 'section-compact'}>
                {topic.questions.map((q, i) => {
                    return <QuestionRow key={i} question={q} conversationUuid={conversationUuid} cits={cits} citCount={citCount} dateTime={props.dateTime} />
                })
                }
                <SingleTopicRating topic={topic} scope={'question'} />
            </div>
        </div>
    )
}

export default TopicContainer;