import './rating.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessages, updateMessage } from '../../../slice/MessageSlice';
import AddCommentModal from './AddCommentModal';
import { clickStarsGA } from '../../../GoogleAnalytics4/GoogleAnalyticsEvents';
import { selectStreaming, selectTranslating } from '../../../slice/loaderSlice';
import { setShowAddComment } from '../../../slice/ModalVisibilitySlice';
import { selectTopicRatings, setTopicRating } from '../../../slice/topicRatingSlice';

function SingleTopicRating(props) {
    const stars = [];
    const topic = props.topic;
    const dispatch = useDispatch();
    const [showAddComment, setShowAddComment2] = useState(false);
//    const message = useSelector(selectMessages)[props.ind];
    const topicRatings = useSelector(selectTopicRatings);
    const streaming = useSelector(selectStreaming);
    const translating = useSelector(selectTranslating);
    const wait = (streaming || translating ? true : false);

    function clickStar(i) {
        if (!wait) {
            dispatch(setTopicRating([topic.topic_uuid, i]))
            clickStarsGA();
            setShowAddComment2(true);
            dispatch(setShowAddComment(true));
        }
    }

    for (let i = 1; i < 6; i++) {
        stars[i] = <span key={i}
            className={ topicRatings[topic.topic_uuid] >= i ? 'rating-icon full' : 'rating-icon empty'}
            onClick={() => clickStar(i)} />
    }

    return (
        <div className='message-section'>
            <div name="ref-title" style={{alignItems: 'center', paddingTop: '0', paddingBottom: '5px', cursor: 'default'}}>
                <p></p>
                <span className={'display-align-center' + (wait ? ' disable-stars' : '')}>{stars}</span>
                <AddCommentModal topic={topic} stars={stars} scope={props.scope} addComment={{ showAddComment: showAddComment, setShowAddComment2: setShowAddComment2 }} />
            </div>
        </div>
    );
}

export default SingleTopicRating;