import { Button, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectPromptFirstLogin, setPromptFirstLogin } from '../../../../slice/ModalVisibilitySlice.js';
import { selectTheme } from '../../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';
import { useEffect, useState, useRef } from 'react';
import SelectAim from './SelectAim.js';
import { Url } from '../../../../constants/global.js';
import { selectErrModalVal, setErrModalVal, setErrModalValInit } from '../../../../slice/errModalValidationSlice.js';
import SelectInterest from './SelectInterest.js';
import SelectField from './SelectField.js';
import { selectBearer } from '../../../../slice/bearerSlice.js';
import AccountIcon from '../../../../Images/AccountIcon.js';

function PromptFirstLoginModal() {
    const show = useSelector(selectPromptFirstLogin);
    const dispatch = useDispatch();
    const theme = useSelector(selectTheme);
    const [promptFields, setPromptFields] = useState({ field: '', interest: '', aim: '' });
    const errValidation = useSelector(selectErrModalVal);
    const logToken = useSelector(selectBearer);
    const firstRender = useRef(true);
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        if (promptFields.field === '' && firstRender.current) {
            dispatch(setErrModalVal(false));
            firstRender.current = false;
        }
    }, [])

    useEffect(() => {
        if (logToken === '' && show) {
            handleClose();
        }
    })

    useEffect(()=>{
        if( promptFields.field !== '' && promptFields.interest !== '' && promptFields.aim !== ''
            && promptFields.field !== 'Other' && promptFields.interest !== 'Other' && promptFields.aim !== 'Other' ) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [promptFields])

    const handleClose = () => {
        dispatch(setPromptFirstLogin(false));
        dispatch(setErrModalValInit());
        setPromptFields({ field: '', interest: '', aim: '' });
        setDisable(true);
    };

    function sendPromptInfo() {
        setDisable(true);
        const fetchPromise = fetch(Url + '/varchat/api/auth/first-access', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
            body: JSON.stringify({ field: promptFields.field, interest: promptFields.interest, aim: promptFields.aim })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    setDisable(false);
                    dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    handleClose();
                }
            })
            .catch((error) => {
                setDisable(false);
                console.error(`Could not get products: ${error}`);
            });
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className={'modal '+ theme} centered={isMobile ? 'centered' : false}>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <AccountIcon />
                        Customize Your VarChat Profile</Modal.Title>
                    <Button onClick={() => handleClose()} id='close' className='close-button'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <div className='mb-3 px-0'><b><span style={{ color: 'var(--green)' }}>Enhance your VarChat journey:</span><br/><span> complete this form for a tailored experience!</span></b></div>
                            <p className='p-label-new mb-2 px-0'>I am a professional in</p>
                            <SelectField setPromptFields={setPromptFields} />
                            <p className='p-label-new mb-2 px-0 mt-4'>Focusing primarily on</p>
                            <SelectInterest setPromptFields={setPromptFields} />
                            <p className='p-label-new mb-2 px-0 mt-4'>My goal in using VarChat is to</p>
                            <SelectAim setPromptFields={setPromptFields} />
                            {errValidation.show ? <p className='errorMessage mt-2 mb-0'>{errValidation.value}</p> : null}
                            <Button className={'primary-button '+ (errValidation.show ? 'mt-3': 'mt-4')} onClick={sendPromptInfo} disabled={disable}>CONTINUE</Button>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PromptFirstLoginModal;