import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setInfo, setShowHelpCenter, setShowProfile } from '../../../slice/ModalVisibilitySlice';
import { setInputMessage } from "../../../slice/InputMessageSlice";
import arrow from '../../../Images/arrow.svg';


function HistoryRow(props) {
    const data = props.savedSearch;
    const dispatch = useDispatch();

    const handleClick = (e) => {
        dispatch(setShowProfile({show: false, type: 'profileButton'}));
        dispatch(setInfo(false));
        dispatch(setShowHelpCenter(false));
        dispatch(setInputMessage(data));
    };

    return (
        <div style={{'borderBottom': '1px solid var(--blue-10)', margin: '0 20%', cursor: 'pointer'}}>
            <span className='span-j-space-b' onClick={handleClick}>
                <p className='history-row' title={data}>{data}</p>
                <p title="Search"><img src={arrow} alt='arrow' className='history-row-arrow' /></p> {/* +6px */}
            </span>
        </div>
    );
}

export default HistoryRow;