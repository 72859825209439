import { useSelector, useDispatch } from 'react-redux';
import { selectShowHelpCenter, setShowHelpCenter, setShowLogin, setShowProfile } from '../../../slice/ModalVisibilitySlice';
import SendArrow from '../../../Images/SendArrow';
import { selectDevice } from '../../../slice/deviceSlice';
import { useEffect, useRef, useState } from 'react';
import ExampleList from '../../MessageContainer/ExampleList';
import { selectBearer } from '../../../slice/bearerSlice';

function HelpCenterPage() {
    const dispatch = useDispatch();
    const device = useSelector(selectDevice);
    const [width, setWidth] = useState(0);
    const backRef = useRef(null);
    const headerRef = useRef(null);
    const padding = device === 'pc' ? 48 : 32; //somma dei padding sx e dx
    const show = useSelector(selectShowHelpCenter);
    const logToken = useSelector(selectBearer);

    useEffect(() => {
        setWidth(headerRef.current.offsetWidth - (backRef.current.offsetWidth * 2) - padding);
    });

    const handleClose = () => {
        dispatch(setShowHelpCenter(false));
    };

    function openProfile() {
        if (logToken !== '') {
            handleClose();
            dispatch(setShowProfile({ show: true, type: 'profileButton' }))
        } else {
            dispatch(setShowLogin({ show: true, type: 'settingButton' }))
        }
    }

    return (
        <div className='help-center' style={show ? { height: '100%' } : { display: 'none' }}>
            <div className='pages-header' ref={headerRef}>
                <div className='pages-back-button text-label-style' onClick={handleClose} ref={backRef}><SendArrow />{device === 'pc' ? 'BACK TO VARCHAT' : ''}</div>
                <div className='title' style={{ justifyContent: 'center', width: width }}><svg className='modal-icon help-icon' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm7.46 7.12-2.78 1.15a4.982 4.982 0 0 0-2.95-2.94l1.15-2.78c2.1.8 3.77 2.47 4.58 4.57zM12 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zM9.13 4.54l1.17 2.78a5 5 0 0 0-2.98 2.97L4.54 9.13a7.984 7.984 0 0 1 4.59-4.59zM4.54 14.87l2.78-1.15a4.968 4.968 0 0 0 2.97 2.96l-1.17 2.78a7.996 7.996 0 0 1-4.58-4.59zm10.34 4.59-1.15-2.78a4.978 4.978 0 0 0 2.95-2.97l2.78 1.17a8.007 8.007 0 0 1-4.58 4.58z" fill="#344D66" fillRule="nonzero" /></g></svg>
                    Help center</div>
            </div>
            <div className='pages-body'>
                <p style={{ fontSize: '20px' }}><b>Before you start: tooltips to enhance your search</b></p>
                <div className='mb-4'>
                    <p className='mb-2 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Starting search</b></p>
                    <ul>
                        <li>
                            Insert gene symbols, variant coordinates (at coding, protein, or genomic levels), or rs dbSNP IDs.
                        </li>
                        <li>
                            Always use official gene symbols to guide the search. If querying by transcript, try to use the latest version.
                        </li>
                        <li>
                            When using HGVS standard nomenclature, you can use:
                            <ul>
                                <li>
                                    Coding (e.g., “PINK1:c.926G&gt;A”)
                                </li>
                                <li>
                                    Protein (e.g., “PINK1:p.G309D”)
                                </li>
                                <li>
                                    Both (e.g., “PINK1:p.G309D, c.926G&gt;A”)
                                </li>
                            </ul>
                        </li>
                        <li>
                            For mitochondrial variants, you can use “m.” HGVS nomenclature (e.g., “m.11778G&gt;A”).
                        </li>
                        <li>
                            Genomic coordinates are supported both as HGVS g. (e.g., “GRCh38:11:g.77213978T&gt;C”) or VCF-like coordinates (e.g., “GRCh38 11 77213978 T C”). The assembly must be specified (supported assemblies are GRCh37, GRCh38, and hg19).
                        </li>
                        <li>
                            Users can also query by gene only to know more about its function and disease implications.
                        </li>
                    </ul>
                    <p className='mb-0'>For more details on specific query types, please visit the dedicated sections of this Help Center.</p>
                </div>
                <hr />


                <div className='mt-4 mb-4'>
                    <p className='mb-2 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Continue the conversation with VarChat!</b></p>
                    <ul>
                        <li>
                            Following your query, VarChat will suggest relevant questions focused on deepening your exploration of the literature. These questions are designed to help you uncover detailed on your topics of interest.
                        </li>
                        <li>
                            Simply select a question from the options provided to continue the conversation, allowing VarChat to guide you through complex information.
                        </li>
                        <li>
                            VarChat's interactive approach makes it easy to refine your search, pinpoint precise information, and access the most relevant insights from your research. 
                        </li>
                    </ul>
                </div>
                <hr />



                <div className='mt-4 mb-4'>
                    <p className='mb-2 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Scanning and retrieving literature</b></p>
                    <ul>
                        <li>
                            VarChat will scan the entire free full-text literature and retrieve matching publications. If publications are found, they will be shown at the bottom of the answer. Click on each reference to explore the full text.
                        </li>
                        <li>
                            If the variant you searched for is mentioned in the supplementary material of a paper, this information will be shown.
                        </li>
                        <li>
                            If the variant is present in ClinVar, the corresponding records will be displayed at the bottom of the answer. Click on them to explore the ClinVar page.
                        </li>
                    </ul>
                </div>
                <hr />
                <div className='mt-4 mb-4'>
                    <p className='mb-2 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Reviewing VarChat answers</b></p>
                    <p>VarChat uses generative AI to draft summaries for each variant or gene. Specifically:</p>
                    <ul>
                        <li>
                            If the variant or gene is referenced in the full text of scientific papers, that text serves as additional content for the summarization.
                        </li>
                        <li>
                            If not, the response is entirely produced by the VarChat generative AI model. In this case, a warning will notify the user that the variant or gene is not described in the literature.
                        </li>
                    </ul>
                </div>
                <hr />
                <div className='mt-4 mb-4'>
                    <p className='mb-2 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Translate each answer in more than 30 different languages</b></p>
                    To enable the translation feature, users must be logged in. <br />
                    During the Sign-Up process, users will be asked to set a preferred language. If this language is different from English, a “Translate to” button will appear next to each answer, displaying the flag corresponding to the selected language. By selecting this button, the translation will be performed and shown.
                    <br />
                    Unregistered users will see a “Need a translation” button near each answer. Upon clicking this button, users will be prompted to Log In (or Sign Up, if they still do not have an account) and then change their language settings.
                </div>
                <hr />
                <div className='mt-4 mb-4'>
                    <p className='mb-2 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Changing Language Settings</b></p>
                    Logged-in users can change their language settings directly in the <span className='message-link' onClick={openProfile}>Profile section</span>, by selecting a language from the “Preferred Language” drop-down menu.
                    <br />
                    After saving the new settings, translations in the selected language will be available for each VarChat answer, by clicking on the button “Translate to” , displaying the flag corresponding to the selected language.
                </div>
                <hr />
                <div className='mt-4 mb-4'>
                    <p className='mb-2 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Input accuracy</b></p>
                    VarChat is a versatile system that relies on your input, so be cautious of typos as they might not always be detected.
                </div>
                <hr />
                <p className='mt-4' style={{ fontSize: '20px' }}><b>Supported query formats</b></p>
                <div>
                    <p className='mb-2 mt-3 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Query by HGVS coding and protein</b></p>
                    <ExampleList type='status_8' />
                    <p className='mb-2 mt-3 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Query by dbSNP rs id</b></p>
                    <ExampleList type='status_4' />
                    <p className='mb-2 mt-3 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Query by genomic coordinates</b></p>
                    <ExampleList type='status_6' />
                    <p className='mb-2 mt-3 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        <b>Query by gene symbols</b></p>
                    <ExampleList type='status_7' />
                </div>
            </div>
        </div>
    );
}

export default HelpCenterPage;