import { Url } from '../../../constants/global';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import './History.css';
import { selectBearer, setExpired } from '../../../slice/bearerSlice';
import { selectRefreshHistory, setRefreshProfileHistory } from '../../../slice/profileHistorySlice';
import HistoryRow from './HistoryRow.js';

function HistoryPage(props) {
    const dispatch = useDispatch();
    const logToken = useSelector(selectBearer);
    const [historyList, setHistoryList] = useState(null);
    const refresh = useSelector(selectRefreshHistory);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [triggerDeleteAfterUpBearer, setTriggerDeleteAfterUpBearer] = useState(false);
    const [triggerRefreshAfterUpBearer, setTriggerRefreshAfterUpBearer] = useState(false);


    useEffect(() => {
        fetchHistory();
    }, [logToken]) // non vede subito il bearer dopo il login..

    useEffect(() => {
        if(refresh) fetchHistory();
    }, [refresh])

    useEffect(()=>{
        if(triggerDeleteAfterUpBearer) { //To trigger the function that had given 401 because the token had expired
            setTriggerDeleteAfterUpBearer(false);
            if(logToken !== '') onDeleteClick();
        }
        if(triggerRefreshAfterUpBearer) {
            setTriggerRefreshAfterUpBearer(false);
            if(logToken !== '') fetchHistory();
        }
    }, [logToken])

    const isHistoryEmpty = () => {
        try {
            return historyList.length === 0;
        } catch(e) {
            return true;
        }
    }

    async function fetchHistory () {
        if(logToken) {
            const fetchPromise = fetch(Url + '/varchat/api/user-info/history', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${logToken}`
                }
            });

            fetchPromise
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            dispatch(setExpired(true));
                            setTriggerRefreshAfterUpBearer(true);
                        }
                        throw new Error(`HTTP error: ${response.status}`);
                    } else {
                        return response.json();
                    }
                }).then((data) => {
                    setHistoryList(data);
                    dispatch(setRefreshProfileHistory(false));
                })
                .catch((error) => {
                    dispatch(setRefreshProfileHistory(false));
                    console.error('Failed to fetch history:', error);
                });
        }
    }

    function onDeleteClick () {
        setDeleteInProgress(true);
        const fetchPromise = fetch(Url + '/varchat/api/user-info/clear-history', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });

        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        dispatch(setExpired(true));
                        setTriggerDeleteAfterUpBearer(true);
                    }
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    dispatch(setRefreshProfileHistory(true));
                    setDeleteInProgress(false);
                }
            })
            .catch((error) => {
                setDeleteInProgress(false);
                console.error(`Failed to delete history: ${error}`);
            });
    }

    return (
        <>
        {
            isHistoryEmpty()
                ? ( <div>
                        <p style={{textAlign: 'center', marginTop: '30px'}}>No history available</p>
                    </div>
                )
                : ( <div>
                        <span style={{display: 'flex', justifyContent: 'center', margin: '15px 0'}}>
                            <button type="button" className="delete-button mb-0 red-button small display-align-center btn btn-primary" onClick={deleteInProgress ? null : onDeleteClick} style={deleteInProgress ? {cursor: "wait"} : {}}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5 19V6H4V4h5V3h6v1h5v2h-1v13c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 17 21H7zM17 6H7v13h10V6zM9 17h2V8H9v9zm4 0h2V8h-2v9z" fill="#ee8373"></path></svg> <span>DELETE HISTORY</span>
                            </button>
                        </span>
                        <div>
                            { historyList.map((search, i) => {
                                return <HistoryRow key={i} savedSearch={search} />
                            }) }
                        </div>
                    </div>
                )
        }
        </>
    );
}

export default HistoryPage;