import { useDispatch, useSelector } from "react-redux";
import { selectInputMessage, selectSend, setInputMessage, setSend } from "../../slice/InputMessageSlice";
import { selectStreaming, selectTranslating, selectWaitingForMaxQueryResponse } from "../../slice/loaderSlice";
import { useEffect, useRef } from 'react';
import { isMobile } from "react-device-detect";

function SendMessageBar() {
    const inputMessage = useSelector(selectInputMessage);
    const send = useSelector(selectSend);
    const dispatch = useDispatch();
    const streaming = useSelector(selectStreaming);
    const waitingForMaxQueryResponse = useSelector(selectWaitingForMaxQueryResponse);
    const translating = useSelector(selectTranslating);
    const wait = (streaming || translating ? true : false);
    const inputRef = useRef(null);
    const firstRender = useRef(true);

    useEffect(() => {
        if (!firstRender.current || !isMobile) {
            inputRef.current.focus();
        }
        firstRender.current = false;
    }, [inputMessage])

    function handleChange(e) {
        dispatch(setInputMessage(e.target.value));
    }

    function keyDown(e) {
        if (e.key === 'Enter' && !wait) {
            dispatch(setSend(!send));
        }
    }

    return (
        <input type='text' className="input-message" value={inputMessage} disabled={waitingForMaxQueryResponse ? true : false} onChange={(e) => { handleChange(e) }} onKeyDown={keyDown} maxLength={120} ref={inputRef}></input>
    );
}

export default SendMessageBar;